import {
  useListServicesForAccountQuery,
  V1PhaseResource,
  V1ProductResource,
  V1ResourceResource,
  V1ServiceCategoryResource,
  V1SubserviceResource,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Subservice, Product } from "../../types";

interface Standard {
  id: number;
  type?: "services";
  name: string;
  category?: string;
  phase?: string;
  hours?: string;
  description?: string;
  subservices?: Subservice[];
  products?: Product[];
  resource?: { id: number; name: string };
}

const useListStandards = ({
  pageSize,
  currentPage,
  filterParams,
  sortBy,
  sortOrder,
}) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null; // Declare customError
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListServicesForAccountQuery({
      slug: accountSlug,
      include: [
        "subservices",
        "products",
        "service-category",
        "phase",
        "resource",
        "subservices.resource",
      ],
      page: { number: currentPage, size: pageSize },
      filter: { ...filterParams, state: "active" },
      sort: sortBy ? [sortOrder == "asc" ? sortBy : `-${sortBy}`] : undefined,
    });

  let standards: Standard[] = [];

  if (data?.data) {
    try {
      standards = data.data.map((standard) => {
        if (!standard.id) {
          throw new Error("Missing standard.id");
        }
        if (!standard.attributes?.name) {
          throw new Error("Missing standard.attributes.name");
        }

        const phase = data?.included?.find(
          (i) =>
            i.type === "phases" &&
            i.id === standard?.relationships?.phase?.data?.id
        ) as V1PhaseResource;

        const category = data?.included?.find(
          (i) =>
            i.type === "service-categories" &&
            i.id === standard?.relationships?.["service-category"]?.data?.id
        ) as V1ServiceCategoryResource;

        const resourceId = standard?.relationships?.resource?.data?.id;
        const resource = resourceId
          ? (data?.included?.find(
              (i) => i.type === "resources" && i.id === resourceId
            ) as V1ResourceResource)
          : undefined;

        const subservices = (
          standard?.relationships?.subservices?.data || []
        ).map((sub) => {
          const s = data?.included?.find(
            (i) => i.type === "subservices" && i.id === sub.id
          ) as V1SubserviceResource;

          const subResourceId = s?.relationships?.resource?.data?.id;
          const subResource = subResourceId
            ? (data?.included?.find(
                (i) => i.type === "resources" && i.id === subResourceId
              ) as V1ResourceResource)
            : undefined;
          return {
            id: s?.id || 0,
            type: "subservices" as "subservices",
            name: s?.attributes?.name || "",
            qty: s?.attributes?.quantity ? String(s.attributes.quantity) : "",
            hours: s?.attributes?.["suggested-hours"]
              ? //@ts-ignore
                parseFloat(s.attributes["suggested-hours"]).toFixed(1)
              : "",
            resource: subResource?.id
              ? {
                  id: subResource.id,
                  name: subResource?.attributes?.name || "",
                }
              : resource?.id
              ? {
                  id: resource.id,
                  name: resource?.attributes?.name || "",
                }
              : undefined,
          };
        });

        const products = (standard?.relationships?.products?.data || []).map(
          (product) => {
            const p = data?.included?.find(
              (i) => i.type === "products" && i.id === product.id
            ) as V1ProductResource;
            return {
              id: Number(p?.id) || 0,
              name: p?.attributes?.name || "",
              mpn: p?.attributes?.["manufacturer-part-number"] || "",
              price: p?.attributes?.["unit-price"]
                ? //@ts-ignore
                  parseFloat(p.attributes["unit-price"]).toFixed(1)
                : "",
            };
          }
        );

        return {
          id: standard.id,
          type: "services",
          name: standard.attributes.name,
          category:
            category?.attributes?.["nested-name"] || category?.attributes?.name,
          phase: phase?.attributes?.name || "",
          hours: standard.attributes?.["total-hours"]
            ? //@ts-ignore
              parseFloat(standard.attributes["total-hours"]).toFixed(1)
            : "",
          description: standard.attributes?.["service-description"] || "",
          subservices,
          products,
          resource: resource?.id
            ? { id: resource.id, name: resource?.attributes?.name || "" }
            : undefined,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }
  return {
    standards,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListStandards;
