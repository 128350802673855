import { useEffect, useState } from "react";
import "../style/style";
import useListStandards from "./api/useListStandards";
import DataTable from "@components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faSortDown,
  faSortUp,
  faSort,
} from "@fortawesome/pro-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ServicePreview from "../../../../components/ServicePreview/ServicePreview";
import { Service } from "../types";
import { useLocation } from "react-router";
import Select from "react-select";
import { ServicesTable } from "../../../Services/ServicesTable/ServicesTable";

const Standards = ({ project_id }) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceType =
    queryParams.get("service_type") === "managed_services"
      ? "managed_services"
      : "professional_services";

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedStandards, setSelectedStandards] = useState<Service[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterParams, setFilterParams] = useState<{
    service_type: "professional_services" | "managed_services";
    search?: string;
  }>({
    service_type: serviceType,
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [serviceToPreview, setServiceToPreview] = useState<Service>({
    id: 0,
    name: "",
  });
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false);

  //step 1 = select standards, step 2 = review & apply
  const [step, setStep] = useState<number>(1);

  const { standards, isLoading, isFetching, pageMeta } = useListStandards({
    filterParams,
    pageSize,
    currentPage,
    sortBy,
    sortOrder,
  });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName(searchInput);
  };

  const searchName = (input: string) => {
    setFilterParams({ ...filterParams, search: searchInput });
  };

  const handleSort = (columnId: string) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const renderSortIcon = (columnId: string) => {
    if (sortBy === columnId) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const selectColumns = [
    {
      id: "name",
      header: () => (
        <div className="displayFlex">
          Standard Name
          <div className="sortIcon" onClick={() => handleSort("name")}>
            {renderSortIcon("name")}
          </div>
        </div>
      ),
      cell(props) {
        return (
          <div
            className={props.row.original.id === -1 ? "" : "projectServiceName"}
          >
            {props.row.original.name}
          </div>
        );
      },
    },
    {
      id: "serviceCategory",
      header: () => (
        <div className="displayFlex">
          Service Category
          <div
            className="sortIcon"
            onClick={() => handleSort("service_category.nested_name")}
          >
            {renderSortIcon("service_category.nested_name")}
          </div>
        </div>
      ),
      cell(props) {
        return <div>{props?.row?.original?.category || ""}</div>;
      },
    },
    {
      header: "Preview",
      cell(props) {
        return props.row.original.id === -1 ? (
          ""
        ) : (
          <div className="previewService">
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => {
                setServiceToPreview(props.row.original);
                setSlideoutOpen(true);
              }}
            />
          </div>
        );
      },
    },
    {
      id: "phase",
      header: () => (
        <div className="displayFlex">
          Phase
          <div className="sortIcon" onClick={() => handleSort("phase.name")}>
            {renderSortIcon("phase.name")}
          </div>
        </div>
      ),
      cell(props) {
        return <div>{props?.row?.original?.phase || ""}</div>;
      },
    },
    {
      header: "Total Hours",
      cell(props) {
        return (
          <div className="alignRight">{props?.row?.original?.hours || ""}</div>
        );
      },
    },
  ];

  const reviewColumns = [
    {
      header: "Service Name",
      cell(props) {
        return props.row.original.id !== "noRow" ? (
          <div className="serviceName">
            <Form.Control
              type="text"
              value={props.row.original.name}
              disabled={true}
            />
            {props.row.original.type === "services" && (
              <div className="previewService review">
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    setServiceToPreview(props.row.original);
                    setSlideoutOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          props.row.original.name
        );
      },
    },
    {
      header: "Total Hours",
      cell(props) {
        return (
          props.row.original.id !== "noRow" && (
            <Form.Control
              type="text"
              value={props.row.original.hours}
              disabled={true}
            />
          )
        );
      },
    },
    {
      header: "QTY",
      cell(props) {
        return (
          props.row.original.id !== "noRow" && (
            <Form.Control
              type="number"
              min="0"
              step="1"
              defaultValue={props.row.original.qty || 1}
            />
          )
        );
      },
    },
    {
      header: "Resource",
      cell(props) {
        const resource = props.row.original?.resource;
        return (
          props.row.original.id !== "noRow" && (
            <Select
              className="resourceDropdown"
              defaultValue={
                resource
                  ? {
                      label: resource.name,
                      value: resource.id,
                    }
                  : undefined
              }
              options={[]}
            />
          )
        );
      },
    },
  ];

  useEffect(() => {
    const cart = [...selectedStandards];
    selectedIds.forEach((id) => {
      const standard = standards.find((s) => String(s.id) === id);
      const standardInCart = cart.find((s) => String(s.id) === id);
      if (standard && !standardInCart) {
        cart.push(standard);
      }
    });
    setSelectedStandards(
      cart.filter((s) => selectedIds.includes(String(s.id)))
    );
  }, [selectedIds]);

  return (
    <div className="servicesV2">
      <div className="actionBtns">
        <div className="leftHeader">
          {" "}
          <Button
            className="button cancelButton"
            onClick={(e) => {
              if (step === 1) {
                window.location.replace(
                  `${appHost}/projects/${project_id}/project_editor`
                );
              } else {
                setStep(1);
              }
            }}
          >
            Cancel
          </Button>
          <div className="servicesH1">Add Services from Standards</div>
        </div>{" "}
        <Button
          className={`button ${selectedIds.length < 1 ? "disabled" : ""}`}
          onClick={() => {
            if (step === 1) {
              setStep(2);
            }
          }}
          disabled={selectedIds.length < 1}
        >
          {step === 1 ? "Continue" : "Apply"}
        </Button>
      </div>
      <div className="servicesH2">
        {step === 1
          ? "1. Select desired Standard(s) to continue."
          : "2. Review & apply selected Standard(s)."}
      </div>
      {step === 1 && (
        <div className="searchField">
          <FormFieldLabel label="Search Standards" className={undefined} />
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCurrentPage(1);
                handleSearch(e);
              }
            }}
            onSubmit={(e) => {
              setCurrentPage(1);
              handleSearch(e);
            }}
          >
            <SearchField
              placeholder="Search existing standards"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onClick={(e) => {
                setSearchInput("");
                setFilterParams({ ...filterParams, search: undefined });
              }}
              fullWidth
            />
          </Form>
        </div>
      )}

      {isLoading || isFetching ? (
        <SmallSpinner />
      ) : step === 1 ? (
        <DataTable
          data={
            standards?.length
              ? standards
              : [{ id: -1, name: "No standards found" }]
          }
          columns={
            serviceType === "professional_services"
              ? selectColumns
              : selectColumns.filter((column) => column.id !== "phase")
          }
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          totalRows={pageMeta?.recordCount}
          totalPages={pageMeta?.pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={(num) => setPageSize(num)}
          paginationEnabled
          pageSizeEnabled
          selectable={standards?.length > 0}
          bordered
          striped
          hover
        />
      ) : (
        <Form>
          <ServicesTable
            servicesPassed={selectedStandards}
            onUpdateServices={(services) => null}
            serviceColumns={reviewColumns}
            subserviceColumns={reviewColumns}
          />
        </Form>
      )}
      <ServicePreview
        service={serviceToPreview}
        slideoutOpen={slideoutOpen}
        setSlideoutOpen={setSlideoutOpen}
        isManagedService={serviceType === "managed_services"}
      />
    </div>
  );
};

export default Standards;
